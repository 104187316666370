import React from "react"
import Chip from "@mui/material/Chip"
import { Box } from "@mui/system"

const TaxonomyChips = ({ terms, getChipUrl }) => {
  return (
    <Box>
      {terms.map(({ id, title }) => (
        <Chip
          key={id}
          label={title}
          clickable
          href={getChipUrl(id)}
          component="a"
          sx={{ mr: 1 }}
        />
      ))}
    </Box>
  )
}

export default TaxonomyChips
