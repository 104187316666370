import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import BuilderSmall from "../components/BuilderSmall"
import Layout from "../components/layout"
import ImageGallery from "../components/ImageGallery"
import DateLine from "../components/DateLine"
import LongtextMarkdown from "../components/LongtextMarkdown"
import ComposedContent from "../components/ComposedContent"
import TransportLinkSet from "../components/TransportLinkSet"
import TaxonomyChips from "../components/TaxonomyChips"
import CenterColumn from "../components/CenterColumn"
import { Box } from "@mui/system"
import { Grid, Divider, Chip, Typography } from "@mui/material"
import CardTitle from "../components/CardTitle"

const SetHeading = ({ serialNumber, year, numberOfPieces }) => {
  return (
    <Box sx={{ my: 1 }}>
      <Chip label="Set" color="primary" sx={{ mr: 1 }} />
      <span>{serialNumber}</span> /{" "}
      <span>
        Released <span>{year}</span> / <span>{numberOfPieces} pieces</span>
      </span>
    </Box>
  )
}

const BuildTemplate = ({ data }) => {
  const build = data.contentfulBuild
  const siteTitle = data.site.siteMetadata.title
  const builders = build.builders
  const nextBuild = data.nextBuild.edges[0]
    ? data.nextBuild.edges[0].node
    : null
  const prevBuild = data.prevBuild.edges[0]
    ? data.prevBuild.edges[0].node
    : null

  return (
    <Layout breadcrumbs={[{ url: "/builds", title: "All Builds" }]}>
      <Helmet title={`${build.title} | ${siteTitle}`} />

      <CenterColumn>
        <Box sx={{ my: 4 }}>
          <DateLine date={build.buildDate} format="MMMM YYYY" />
          <Typography variant="h2" align="left" sx={{ py: 0 }}>
            {build.title}
          </Typography>
          <TransportLinkSet
            next={nextBuild ? `/build/${nextBuild.slug}` : null}
            prev={prevBuild ? `/build/${prevBuild.slug}` : null}
            itemLabel="Build"
          />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            {build.primaryMediaFull && <ImageGallery {...build} />}
            {build.set && <SetHeading {...build.set} />}
          </Grid>
          <Grid item xs={12} sm={4}>
            {build.types && (
              <TaxonomyChips
                terms={build.types}
                getChipUrl={id => `/builds?fields.types.sys.id=${id}`}
              />
            )}
            <LongtextMarkdown value={build.description} />
            <CardTitle variant="h4">Built by:</CardTitle>
            <Box sx={{ my: 1 }}>
              <Grid container spacing={2}>
                {builders.map(builder => (
                  <Grid item xs={3}>
                    <BuilderSmall
                      key={`builder-${builder.id}`}
                      builder={builder}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CenterColumn>
      {build.components && (
        <>
          <Divider sx={{ my: 5 }} />
          <ComposedContent components={build.components} />
        </>
      )}
    </Layout>
  )
}

export default BuildTemplate

export const pageQuery = graphql`
  query BuildBySlug($slug: String!, $buildDate: Date!, $createdAt: Date!) {
    site {
      siteMetadata {
        title
      }
    }
    prevBuild: allContentfulBuild(
      limit: 1
      filter: { buildDate: { lte: $buildDate }, createdAt: { lt: $createdAt } }
      sort: { fields: [buildDate, createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          slug
        }
      }
    }
    nextBuild: allContentfulBuild(
      limit: 1
      filter: { buildDate: { gte: $buildDate }, createdAt: { gt: $createdAt } }
      sort: { fields: [buildDate, createdAt], order: [ASC, ASC] }
    ) {
      edges {
        node {
          slug
        }
      }
    }
    contentfulBuild(slug: { eq: $slug }) {
      title
      buildDate
      components {
        __typename
        ...textComponent
        ...headingComponent
        ...scrubberComponent
        ...videoComponent
        ...buildCardFragment
        ...dividerComponent
        ...imageComponent
      }
      primaryMediaFull: primaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1800)
        file {
          contentType
          url
        }
      }
      primaryMediaThumbnail: primaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1000, placeholder: BLURRED)
      }
      secondaryMediaThumbnails: secondaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 450)
      }
      secondaryMediaFull: secondaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1800)
        file {
          contentType
          url
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      types {
        title
        id: contentful_id
      }
      builders {
        name
        id
        slug
        picture {
          gatsbyImageData(layout: CONSTRAINED, width: 450)
        }
      }
      set {
        id
        year
        title
        numberOfPieces
        serialNumber
      }
    }
  }
`
