import React from "react"
import ChevronRight from "@mui/icons-material/ChevronRight"
import ChevronLeft from "@mui/icons-material/ChevronLeft"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import ResponsiveButtonSet from "../ResponsiveButtonSet"

const TransportLinkSet = ({ next, prev, itemLabel }) => {
  const Label = ({ text }) => <span>{text}</span>

  return (
    <Box sx={{ my: 2 }}>
      <ResponsiveButtonSet>
        {prev && (
          <Button href={prev} size="small">
            <ChevronLeft />
            <Label text={`Previous ${itemLabel}`} />
          </Button>
        )}
        {next && (
          <Button href={next} size="small">
            <Label text={`Next ${itemLabel}`} />
            <ChevronRight />
          </Button>
        )}
      </ResponsiveButtonSet>
    </Box>
  )
}

export default TransportLinkSet
