import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import Lightbox from "react-image-lightbox"

import "react-image-lightbox/style.css"
import { Grid } from "@mui/material"
import { Box } from "@mui/system"
import styled from "@emotion/styled"

const ImageGallery = ({
  primaryMediaFull,
  secondaryMediaFull,
  primaryMediaThumbnail,
  secondaryMediaThumbnails,
}) => {
  const [imgIndex, setImgIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const openGallery = imgIndex => {
    setImgIndex(imgIndex)
    setIsOpen(true)
  }

  const ClickableImage = styled(GatsbyImage)(() => ({
    cursor: "pointer",
  }))

  let allMediaFull = []
  if (secondaryMediaFull) {
    // Clone the array.
    allMediaFull = secondaryMediaFull.slice(0)
  }
  allMediaFull.unshift({ ...primaryMediaFull })

  const nextImgIndex = (imgIndex + 1) % allMediaFull.length
  const prevImgIndex =
    (imgIndex + allMediaFull.length - 1) % allMediaFull.length
  const currentImg = allMediaFull[imgIndex]
  const nextImg = allMediaFull[nextImgIndex]
  const prevImg = allMediaFull[prevImgIndex]

  return (
    <>
      <Box onClick={() => openGallery(0)} sx={{ mb: 2 }}>
        <ClickableImage
          image={primaryMediaThumbnail.gatsbyImageData}
          loading="eager"
          placeholder="blurred"
        />
      </Box>
      {secondaryMediaThumbnails && (
        <Grid container spacing={2}>
          {secondaryMediaThumbnails.map((thumbnail, thumbnailIndex) => (
            <Grid
              item
              xs={6}
              md={3}
              onClick={() => openGallery(thumbnailIndex + 1)}
              key={`gallery_image_${thumbnailIndex + 1}`}
            >
              <ClickableImage image={thumbnail.gatsbyImageData} />
            </Grid>
          ))}
        </Grid>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={currentImg.file.url}
          nextSrc={nextImg.file.url}
          prevSrc={prevImg.file.url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setImgIndex(prevImgIndex)}
          onMoveNextRequest={() => setImgIndex(nextImgIndex)}
        />
      )}
    </>
  )
}

export default ImageGallery
